<template>
	<div class="animated fadeIn">
		<b-card class="card-border px-2 mt-4">
			<!-- Filter  -->
			<b-row class="mt-2 mb-4">
				<b-col sm="12" md="3" lg="3">
					<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
						class="filter">
						FILTER OPTIONS
					</b-button>
				</b-col>
				<b-col sm="12">
					<!-- Collapsible Filter Options -->
					<b-collapse id="collapse-1" class="mt-2">
						<b-card>
							<b-row no-gutters>
								<b-col lg="6" md="10" sm="12">
									<b-form-group label="Company" :description="filterCompanyDescription">
										<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="companyAccessOptions"
											:reduce="(company) => company.value" v-model="filterBy.company">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row no-gutters>
								<b-col lg="4" md="6" sm="12" class="mr-4">
									<b-form-group label="Year">
										<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="yearOptions"
											:reduce="(year) => year.value" v-model="filterBy.year">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
									</b-form-group>
								</b-col>
								<b-col lg="4" md="6" sm="12">
									<b-form-group label="Month">
										<v-select class="style-chooser" label="text" placeholder=" - Please select - " :options="monthOptions"
											:reduce="(month) => month.value" v-model="filterBy.month">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row no-gutters>
								<b-col sm="12">
									<b-button class="mr-1" variant="success" @click="retrieveData">
										Generate
									</b-button>
									<b-button class="mr-1" variant="primary" @click="resetFilters">
										Reset
									</b-button>
								</b-col>
							</b-row>
						</b-card>
					</b-collapse>
				</b-col>
			</b-row>

			<b-card-title><i class="icon-speedometer"></i> &nbsp;{{ title }}</b-card-title>
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

			<!-- Asset Metrics -->
			<b-row class="mt-4">
				<b-col lg="3" md="5" sm="12">
					<b-card no-body class="bg-light">
						<b-card-body class="pb-0">
							<div class="row">
								<div class="col-md-7 pull-left">
									<h4 class="assetMetricsFont">{{ totalAssets }}</h4>
									<p class="stat-label total-assets-label">Total Assets</p>
								</div>
							</div>
						</b-card-body>
					</b-card>
				</b-col>
				<b-col lg="3" md="5" sm="12" v-show="hasDispatchPermission">
					<b-card no-body class="bg-warning">
						<b-card-body class="pb-0">
							<h4 class="mb-3 assetMetricsFont">{{ dispatchedMetrics }}</h4>
							<p class="stat-label">Dispatched</p>
						</b-card-body>
					</b-card>
				</b-col>
				<b-col lg="3" md="5" sm="12" v-show="hasDispatchPermission">
					<b-card no-body class="bg-success">
						<b-card-body class="pb-0">
							<h4 class="mb-3 assetMetricsFont">
								{{ inTransitMetrics }}
							</h4>
							<p class="stat-label">In-Transit</p>
						</b-card-body>
					</b-card>
				</b-col>
				<b-col lg="3" md="5" sm="12" v-show="hasDispatchPermission">
					<b-card no-body class="bg-primary">
						<b-card-body class="pb-0">
							<h4 class="mb-3 assetMetricsFont">{{ receivedMetrics }}</h4>
							<p class="stat-label">Received</p>
						</b-card-body>
					</b-card>
				</b-col>
			</b-row>
		</b-card>

		<!-- Asset Location Summary -->
		<b-card class="card-border px-2">
			<b-row class="my-2">
				<b-col sm="6" md="5">
					<b>ASSET LOCATION SUMMARY</b>
				</b-col>
				<b-col sm="6" md="4" offset-md="3" class="text-md-right">
					<b-input-group prepend="Show" append="/ Page">
						<b-form-select :options="pageOptions" v-model="perPage" />
					</b-input-group>
				</b-col>
			</b-row>

			<b-table show-empty striped hover :items="dashboard.assetLocationSummary" :fields="fields"
				:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
				<template v-slot:cell(totalQuantity)="row">
					<div class="clearfix">
						<div class="float-left numFont">
							<strong>{{ row.item.totalQuantity }}</strong>
						</div>
					</div>
				</template>
				<template v-slot:cell(assetPoolPercentage)="row">
					<div class="clearfix">
						<div class="float-left numFont">
							<strong>{{ row.item.assetPoolPercentage }}%</strong>
						</div>
						<div class="float-right">
							<small class="text-muted">{{ row.item.totalQuantity }} / {{ totalAssets }}</small>
						</div>
					</div>
					<b-progress :value="(row.item.totalQuantity / totalAssets) * 100" class="progress-xs" />
				</template>
			</b-table>

			<b-row class="my-1">
				<b-col md="8" sm="12">
					<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
				</b-col>
				<b-col md="4" sm="12">
					<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
						class="my-0" />
				</b-col>
			</b-row>
		</b-card>

		<!-- Asset Location Map -->
		<b-card class="card-border px-2">
			<div class="my-2" v-if="!isLoading">
				<GoogleMap :assetLocationOverlayStr="assetLocationMap" :company="companyParam"
					:companyOptions="allCompanyOptions" :storageLocationOptions="allStorageLocationOptions"
					:assetTypeOptions="allAssetTypeOptions" :allConnectionsObj="allConnectionsObj" />
			</div>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '../../utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API
import dashboardApi from '@/api/dashboardApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'dashboard',
	components: {
		Loading,
		GoogleMap: () => import('@/views/commons/GoogleMap'),
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'company',
					label: 'Company',
					sortable: true,
				},
				{
					key: 'connections',
					label: 'Connections',
				},
				{
					key: 'assetType',
					sortable: true,
				},
				{
					key: 'totalQuantity',
					label: 'Total',
					sortable: true,
				},
				{
					key: 'assetPoolPercentage',
					label: 'Percentage',
					sortable: false,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// Filter Options
			companyAccessOptions: [],
			yearOptions: [],
			monthOptions: [],

			defaultFilterBy: {
				company: { ...config.companyDefaultValue },
				year: '',
				month: ''
			},
			filterBy: {
				company: { ...config.companyDefaultValue },
				year: '',
				month: ''
			},

			companyParam: {
				name: '',
				id: '',
			},
			monthParam: '',
			yearParam: '',

			// Asset Location Map
			allCompanyOptions: [],
			allStorageLocationOptions: [],
			allAssetTypeOptions: [],

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allAssetTypesObj: {},

			dashboard: {},

			hasDispatchPermission: this.$store.getters.hasDispatchPermission,
			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return this.companyParam.name + ' Asset Summary for ' + this.monthParam + ' ' + this.yearParam;
		},
		totalAssets() {
			let dashboard = this.dashboard ? this.dashboard : {};
			let assetMetrics = dashboard.assetMetrics ? dashboard.assetMetrics : {};
			return assetMetrics.total
				? assetMetrics.total.toLocaleString()
				: 0;
		},
		dispatchedMetrics() {
			let dashboard = this.dashboard ? this.dashboard : {};
			let assetMetrics = dashboard.assetMetrics ? dashboard.assetMetrics : {};
			return assetMetrics.dispatched
				? assetMetrics.dispatched.toLocaleString()
				: 0;
		},
		receivedMetrics() {
			let dashboard = this.dashboard ? this.dashboard : {};
			let assetMetrics = dashboard.assetMetrics ? dashboard.assetMetrics : {};
			return assetMetrics.received
				? this.dashboard.assetMetrics.received.toLocaleString()
				: 0;
		},
		inTransitMetrics() {
			let dashboard = this.dashboard ? this.dashboard : {};
			let assetMetrics = dashboard.assetMetrics ? dashboard.assetMetrics : {};
			return assetMetrics.inTransit
				? assetMetrics.inTransit.toLocaleString()
				: 0;
		},
		assetLocationMap() {
			let dashboard = this.dashboard ? this.dashboard : {};
			let assetLocationMap = dashboard.assetLocationMap ? dashboard.assetLocationMap : {};
			return assetLocationMap
				? JSON.stringify(assetLocationMap)
				: '';
		},
		filterCompanyDescription() {
			return this.filterBy.company ? this.filterBy.company.description : '-';
		}
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				// Company Filter Options
				this.allCompaniesObj = { ...this.$store.getters.companies, ...this.$store.getters.connectedCompanies };
				this.allCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

				// Storage Location Filter Options
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations, ...this.$store.getters.connectedStorageLocations };
				this.allStorageLocationOptions = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);

				// Connection Options
				this.allConnectionsObj = { ...this.$store.getters.connections };

				// Asset Type Filter Options
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allAssetTypeOptions = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

				this.initFilterOptions();
				await this.retrieveData();

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		initFilterOptions() {
			this.companyAccessOptions = [];

			if (this.isSuperAdmin) {
				this.companyAccessOptions = DropDownItemsUtil.retrieveAllCompanyAccess(this.allCompaniesObj);

			} else {
				// Add Sub Companies of the Current Company
				if (
					this.loggedUser.type === config.managerRole ||
					this.loggedUser.type === config.superAdminRole
				) {
					let currCompanyId = this.loggedUserCompany.id;
					let companiesObj = _.filter(this.allCompaniesObj, (o) => {
						return o.id === currCompanyId || o.parentCompanyId === currCompanyId;
					});
					this.companyAccessOptions = DropDownItemsUtil.retrieveAllCompanyAccess(companiesObj);
				}

				// Add Company Access Options
				if (this.loggedUser.companyAccess) {
					let companyAssetOptions = DropDownItemsUtil.retrieveAllCompanyAccess(
						this.loggedUser.companyAccess
					);

					let companyNames = _.map(this.companyAccessOptions, 'text');
					_.forEach(companyAssetOptions, o => {
						if (!companyNames.includes(o.text)) {
							this.companyAccessOptions.push(o);
						}
					});
				}
			}

			this.yearOptions = DropDownItemsUtil.retrieveYearOptions();
			this.monthOptions = DropDownItemsUtil.retrieveMonthOptions();

			this.defaultFilterBy.company = DropDownItemsUtil.getCompanyAccessItem(
				this.loggedUserCompany
			);
			this.defaultFilterBy.month = DateUtil.getCurrentMonth();
			this.defaultFilterBy.year = DateUtil.getCurrentYear();

			this.filterBy = { ...this.defaultFilterBy };
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };
			}
		},
		async retrieveData() {
			// show loading indicator
			this.isLoading = true;

			// update param
			this.companyParam = { ...this.filterBy.company };
			this.monthParam = DateUtil.getMonthName(this.filterBy.month);
			this.yearParam = this.filterBy.year;

			let range = DateUtil.getTimestampRangeForMonth(this.filterBy.year, this.filterBy.month);
			this.filterBy.fromTimestamp = range.start;
			this.filterBy.toTimestamp = range.end;
			this.filterBy.statuses = ['In-Transit', 'Receiving', 'Received'];

			try {
				let { data } = await dashboardApi.getDashboard(
					this.loggedUser.id,
					this.filterBy
				);

				this.dashboard = data;
				this.totalRows = this.dashboard.assetLocationSummary.length;

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
};
</script>

<style scoped>
.stat-label {
	font-size: 14px;
}

.total-assets-label {
	margin-top: 18px;
}

.assetMetricsFont {
	font-family: monospace;
	font-weight: bold;
	font-size: 22px;
}
</style>